<template>
  <div class="relative min-h-screen f-col">
    <template v-if="!hideLayout">
      <vf-button
        v-if="mainContent"
        class="absolute left-0 top-0 ml-4"
        data-test-id="skip-to-main-content"
        size="tiny"
        style="z-index: 10000"
        :style="{ 'margin-top': showSkipLink ? '0.25rem' : '-100%' }"
        to="#main-content"
        variant="primary-inverse"
        @blur="showSkipLink = false"
        @click="mainContent.focus()"
        @focus="showSkipLink = true"
      >
        {{ $t.skipToMainContent }}
      </vf-button>

      <suspense>
        <vf-header-provider />
      </suspense>

      <div
        ref="mainContent"
        id="main-content"
        class="relative"
        style="top: -5rem; margin-top: -1px"
        tabindex="-1"
      />
    </template>

    <slot />

    <template v-if="!hideLayout && !hideFooter">
      <base-lazy-hydrate when="visible">
        <vf-footer ref="footerRef" class="mt-a" :hide-newsletter="auth.loggedIn && !showNewsletterForAuthenticatedUsers" :pending="auth.loggedIn === undefined" />
      </base-lazy-hydrate>
    </template>
  </div>
</template>

<script lang="ts" setup>
const { $feature, $viewport } = useNuxtApp()
const footerRef = useState<HTMLElement>('footerRef')
const route = useRoute()
const loyalty = useLoyaltyStore()
const toast = useToaster()
const { getLoyaltyMessages, isLoyaltyToastNeeded } = useLoyalty()
const auth = useAuthStore()

const mainContent = ref<HTMLDivElement>()
const showSkipLink = ref(false)

const hideLayout = computed(() => route.meta.hideLayout === true)
const hideFooter = useState('hideFooter')

const {
  loyalty: {
    brandClasses: loyaltyClasses,
    logo: loyaltyLogo
  },
  footer: { showNewsletterForAuthenticatedUsers }
} = useAppConfig().components.vf

const displayLoyaltyToast = () => {
  if (!$feature.showLoyaltyRewardsInUtilityNav || !isLoyaltyToastNeeded() || $viewport.lg) return

  const closeLoyaltyToastDelay = 5000

  const loyaltyMessages = computed(() => getLoyaltyMessages())

  toast.add({
    props: {
      type: '',
      variant: 'loyalty',
      classToast: loyaltyClasses.rewardsPreviewTilePopoverContent,
      classMessage: loyaltyClasses.rewardsPreviewTilePopoverContentInner,
      title: loyaltyMessages.value.rewardsGreeting,
      message: loyaltyMessages.value.title,
      bottomText: loyaltyMessages.value.text,
      icon: { src: loyaltyLogo, size: 'xl' }
    },
    delay: closeLoyaltyToastDelay
  })
  const isLoyaltyToastAlreadyDisplayed = useSessionStorage('isLoyaltyToastAlreadyDisplayed', true)
  isLoyaltyToastAlreadyDisplayed.value = true
}

watch (() => loyalty.rewardsAvailable, () => {
  if (loyalty.rewardsAvailable?.length) displayLoyaltyToast()
})
</script>
